import firebase from 'firebase';


export class FirestoreDocumentObserver<T = firebase.firestore.DocumentData> {
    private waitingValueCallbacks: ((data: T|undefined, error?: firebase.firestore.FirestoreError|Error) => void)[] = []
    private _dispose: () => void

    constructor(readonly reference: firebase.firestore.DocumentReference<T>,
                public onUpdate?: (documentData: T|undefined) => void) {
        this._dispose = reference.onSnapshot({
            next: snapshot => {
                const data = snapshot.data()
                if (this.onUpdate) {
                    this.onUpdate(data)
                }
                this.waitingValueCallbacks.forEach(callback => callback(data))
                this.waitingValueCallbacks = []
            },
            error: error => {
                this.waitingValueCallbacks.forEach(callback => callback(undefined, error))
                this.waitingValueCallbacks = []
            },
        })
    }

    nextValue(): Promise<T|undefined> {
        return new Promise((resolve, reject) => {
            this.waitingValueCallbacks.push((data, error) => {
                if (error) {
                    reject(error)
                } else {
                    resolve(data)
                }
            })
        })
    }

    dispose() {
        const waitingError = new Error('Observer was disposed before it got a value')

        this.waitingValueCallbacks.forEach(callback => callback(undefined, waitingError))
        this.waitingValueCallbacks = []

        this._dispose()
    }
}

export class FirestoreQueryObserver {
    private waitingCallbacks: ((error?: firebase.firestore.FirestoreError|Error) => void)[] = []
    private _dispose: () => void
    snapshot: firebase.firestore.QuerySnapshot|null = null

    static async create(query: firebase.firestore.Query): Promise<FirestoreQueryObserver> {
        const result = new this(query)
        await result.waitForSnapshot()
        return result
    }

    private constructor(readonly query: firebase.firestore.Query) {
        this._dispose = query.onSnapshot({
            next: snapshot => {
                this.snapshot = snapshot
                this.waitingCallbacks.forEach(callback => callback())
                this.waitingCallbacks = []
            },
            error: error => {
                this.waitingCallbacks.forEach(callback => callback(error))
                this.waitingCallbacks = []
            },
        })
    }

    async waitForSnapshot(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.waitingCallbacks.push(error => {
                if (error) {
                    if (error instanceof Error) {
                        reject(error)
                    } else {
                        reject(new Error(`Firebase error ${error}`))
                    }
                } else {
                    resolve()
                }
            })
        })
    }

    get docs() {
        return this.snapshot?.docs ?? [];
    }

    dispose() {
        const waitingError = new Error('Observer was disposed before it got a value')

        this.waitingCallbacks.forEach(callback => callback(waitingError))
        this.waitingCallbacks = []

        this._dispose()
    }
}

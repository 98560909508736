











































































































import TweetDialog from '@/components/TweetDialog.vue';

import TinyshaderEngine, { RenderEngineError } from '@/lib/TinyshaderEngine';
import Help from '@/components/Help.vue';
import CodeEditor from '@/components/CodeEditor.vue';
import Vue from 'vue';
import VueContext from 'vue-context';
import RecordControl from '@/components/RecordControl.vue';
import { MutableDatabaseShader, MutableShader, ReadWriteDatabaseShader, UrlShader } from '@/lib/models';
import auth from '@/lib/auth';
import mixins from 'vue-typed-mixins';
import UtilityMixin from '@/components/UtilityMixin'
import PageTitleMixin from '@/components/PageTitleMixin'

const nullShader: MutableShader = {
  code: '',
  name: '',
  creationDate: 0,
  authorUsernames: [],
  parentShaderId: null,
}

export default mixins(UtilityMixin, PageTitleMixin).extend({
  name: 'Shader',
  data() {
    return {
      engine: null as TinyshaderEngine|null,
      shader: nullShader as MutableShader,
      shaderLoading: false,
      shaderLoadError: null as Error|null,
      shaderAuthorsExpanded: false,
      selectedTab: null as string|null,
      auth,
    }
  },
  async mounted() {
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    this.engine = new TinyshaderEngine(canvas);
    this.engine.userCode = this.shader.code
    this.engine.start()
  },
  beforeDestroy() {
    this.engine?.stop();
    this.engine = null;
  },
  methods: {
    async goFullscreen() {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      await canvas.requestFullscreen({
        navigationUI: 'hide',
      })
    },
    async loadShader() {
      await this.waitForMount()

      if (this.$route.name == 'database-shader') {
        await this.getShaderFromDatabase(this.$route.params.id)
      } else {
        this.shader = UrlShader.fromHref() ?? UrlShader.create()
      }

      this.engine?.start();
    },
    async getShaderFromDatabase(id: string) {
      this.shader = nullShader
      this.shaderLoading = true
      this.shaderLoadError = null

      try {
        const databaseShader = await ReadWriteDatabaseShader.get(id)
        if (databaseShader.ownerUID == auth.user?.uid) {
          this.shader = databaseShader
        } else {
          this.shader = UrlShader.fromDatabaseShader(databaseShader)
        }
      }
      catch (error) {
        console.error(error);
        this.shaderLoadError = error
      }
      finally {
        this.shaderLoading = false
      }
    },
    restart() {
      if (!this.engine) return
      this.engine.restart();
    },
    cleanShaderName(name: string) {
      name = name.replaceAll('\n', ' ').trim()

      if (name.length > 100) {
        name = name.slice(0, 100);
      }

      return name
    },
    tabClicked(tabId: string) {
      if (this.selectedTab == tabId) {
        this.selectedTab = null;
      } else {
        this.selectedTab = tabId;
      }
    },
    async saveButtonClicked() {
      // we can reuse these 'load' properties for the initial save, they have
      // similar outcomes
      this.shaderLoading = true

      try {
        const databaseShader = await ReadWriteDatabaseShader.createFromExisting({
          existingShader: this.shader,
          creator: auth.user!,
        })

        await databaseShader.update()
        this.shader = databaseShader;
        this.$router.replace(databaseShader.href)
      }
      catch (error) {
        console.error(error)
        alert('Save shader failed\n\n'+error)
      }
      finally {
        this.shaderLoading = false
      }
    }
  },
  computed: {
    pageTitle(): string {
      const name = this.shader.name;
      if (!name) {
        return 'Tinyshader'
      }

      if (this._shaderAuthors.length == 0) {
        return `${name} - Tinyshader`
      }

      const authors = this._shaderAuthors
      if (authors.length == 1) {
        return `${name} by ${authors[0]} - Tinyshader`
      } else if (authors.length == 2) {
        return `${name} by ${authors[0]} and ${authors[1]} - Tinyshader`
      } else {
        return `${name} by ${authors[0]} and ${authors.length-1} others - Tinyshader`
      }
    },
    error(): RenderEngineError|null {
      return this.engine?.error ?? null;
    },
    shaderAuthorsShort(): string {
      const usernames = this._shaderAuthors
      if (usernames.length == 0) {
        return ''
      } else if (usernames.length > 2) {
        return 'by ' + usernames[0]
      } else {
        return 'by ' + usernames.join(', ')
      }
    },
    shaderAuthorsLong(): string {
      const usernames = this._shaderAuthors
      return 'by ' + usernames.join(', ')
    },
    shaderAuthorsButtonLabel(): string {
      const numAuthors = this._shaderAuthors.length

      if (numAuthors > 2) {
        return this.shaderAuthorsExpanded ? `show less` : `& ${numAuthors-1} more`
      } else {
        return ''
      }
    },
    _shaderAuthors(): string[] {
      const shader = this.shader as MutableDatabaseShader
      return shader.authorUsernames ?? []
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        console.log('route changed')
        this.loadShader().catch(console.error)
      }
    },
    'shader.code': function (code: string) {
      this.waitForMount().then(() => {
        this.engine!.userCode = code
      })
    }
  },
  components: {
    Help,
    TweetDialog,
    CodeEditor,
    VueContext,
    RecordControl,
  },
});








































import Vue from 'vue'
import auth from '@/lib/auth';
import {DatabaseShader, ReadWriteDatabaseShader, Shader, ShaderId, User} from '@/lib/models';
import ShaderView from '@/components/ShaderView.vue';
import {timeAgo, dateFormat} from '@/util';
import mixins from 'vue-typed-mixins';
import PageTitleMixin from '@/components/PageTitleMixin';
import VueContext from 'vue-context';

export default mixins(PageTitleMixin).extend({
  name: 'Browse',
  data() {
    return {
      auth,
      latestShadersLoading: false,
      latestShadersError: null as Error|null,
      hoverShader: null as ShaderId|null,
    }
  },
  methods: {
    timeAgo,
    deleteShaderButtonPressed(shader: DatabaseShader): void {
      const shaderName = shader.name || "Unnamed shader"
      const text = `
        <small>
          ${shaderName}<br>
          ${shader.code.split('\n').length} lines, ${shader.code.length} characters<br>
          Created ${dateFormat(shader.creationDate)} (${timeAgo(shader.creationDate)})<br>
          Last modified ${dateFormat(shader.modificationDate)} (${timeAgo(shader.modificationDate)})<br>
        </small>
        <br>
        This action cannot be undone.
      `;

      this.$modal.show('dialog', {
        title: `Are you sure you want to delete “${shaderName}”?`,
        text,
        buttons: [
          {
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Delete',
            class: 'vue-dialog-button destructive',
            handler: async () => {
              this.$modal.hide('dialog')
              const el = this.$el as HTMLElement
              el.style.cursor = 'progress'
              try {
                const mutableShader = new ReadWriteDatabaseShader(shader)
                mutableShader.isDeleted = true
                await mutableShader.update()
              }
              catch (error) {
                console.error(error)

                this.$modal.show('dialog', {
                  title: `Failed to delete “${shaderName}”`,
                  text: error.toString()
                })
              }
              finally {
                el.style.cursor = ''
              }
            }
          },
        ]
      })
    },
  },
  watch: {
    user: {
      handler() {
        if (this.user) {
          this.latestShadersError = null;
          this.latestShadersLoading = true
          this.user.latestShaders().finally(() => {
            this.latestShadersLoading = false
          }).catch(error => {
            console.error(error);

            this.latestShadersError = error;
          })
        }
      },
      immediate: true,
    }
  },
  computed: {
    pageTitle(): string {
      return `Browse - Tinyshader`
    },
    user(): User|null  {
      return this.auth?.user ?? null;
    },
    myShaders(): Shader[] {
      return this.user?.allShaders ?? []
    },
  },
  components: {
    ShaderView,
    VueContext,
  },
})

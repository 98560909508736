import "@/lib/firebase";
import firebase from 'firebase/app';
import {User} from '@/lib/models';
import { Mutex } from "async-mutex";

const firebaseAuth = firebase.auth();
type AuthStateListener = (user: firebase.User|null)=>void;
const authProvider = new firebase.auth.GithubAuthProvider()

class Auth {
    authStateListeners: AuthStateListener[] = [];
    mutex = new Mutex()

    constructor() {
        firebaseAuth.onAuthStateChanged((firebaseUser) => {
            this.mutex.runExclusive(async () => {
                if (firebaseUser == null) {
                    this.user = null
                } else {
                    if (this.user?.firebaseUser == firebaseUser) {
                        return
                    } else {
                        this.login(firebaseUser)
                    }
                }
            })
        });
    }

    user: User|null|undefined = undefined

    async login(firebaseUser?: firebase.User): Promise<User> {
        return this.mutex.runExclusive(async () => {
            if (!firebaseUser) {
                const result = await firebaseAuth.signInWithPopup(authProvider);
                const githubAccessToken = (result.credential as any).accessToken

                const updateGithubUsername = firebase.functions().httpsCallable('updateGithubUsername')
                await updateGithubUsername({githubAccessToken})

                firebaseUser = result.user!
                // force refresh user token
                await firebaseUser.getIdTokenResult(true)
            }

            const token = await firebaseUser.getIdTokenResult()

            this.user = new User(firebaseUser, token.claims.gh)
            console.log('logged in');

            return this.user
        })
    }

    async logout(): Promise<void> {
        await this.mutex.runExclusive(async () => {
            await firebaseAuth.signOut();
        })
    }

    get isLoggedIn() {
        return !!this.user;
    }
}

const auth = new Auth()
export default auth;

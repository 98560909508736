








import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MiniGraph',
  data() {
    return {
      hover: false,
      density: 1,
    }
  },
  props: {
    expression: {type: String, default: 'x'},
    xRange: {type: Array as PropType<number[]>, default: () => [-4.3, 4.3]},
    yRange: {type: Array as PropType<number[]>, default: () => [-1.1 , 1.1]},
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw(): void {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const ctx = canvas.getContext('2d')!;
      this.density = window.devicePixelRatio ?? 1;

      const pointWidth = 161
      const pointHeight = 41
      const width = pointWidth*this.density
      const height = pointHeight*this.density
      canvas.style.width = `${pointWidth}px`
      canvas.style.height = `${pointHeight}px`
      canvas.width = width;
      canvas.height = height;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';

      if (this.hover) {
        this.drawGradient();
        ctx.globalCompositeOperation = 'exclusion';
      }

      this.drawLine();
    },
    drawLine(): void {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const ctx = canvas.getContext('2d')!;

      const coordToPixels = (x: number, y: number, round=false) => {
        const result = [
          (x - this.xRange[0]) / (this.xRange[1] - this.xRange[0]) * canvas.width,
          (1 + (this.yRange[0] - y) / (this.yRange[1] - this.yRange[0])) * canvas.height,
        ];

        if (round && this.density < 2) {
          // round to the nearest mid-pixel
          result[0] = Math.round(result[0] + 0.5) - 0.5;
          result[1] = Math.round(result[1] + 0.5) - 0.5;
        }
        return result as [number, number];
      };

      // axes
      ctx.strokeStyle = '#aaa';
      ctx.lineWidth = 1*this.density;

      // x axis
      ctx.beginPath();
      ctx.moveTo(...coordToPixels(this.xRange[0], 0, true));
      ctx.lineTo(...coordToPixels(this.xRange[1], 0, true));
      // x axis tick marks
      {
        let x = Math.ceil(this.xRange[0]);
        while (x += 1, x < this.xRange[1]) {
          const [xPixel, yPixel] = coordToPixels(x, 0, true);
          const offset = 1*this.density;
          ctx.moveTo(xPixel, yPixel - offset);
          ctx.lineTo(xPixel, yPixel + offset);
        }
      }
      ctx.stroke();

      // y axis
      ctx.beginPath();
      ctx.moveTo(...coordToPixels(0, this.yRange[0], true));
      ctx.lineTo(...coordToPixels(0, this.yRange[1], true));
      {
        let y = Math.ceil(this.yRange[0]);
        while (y < this.yRange[1]) {
          const [xPixel, yPixel] = coordToPixels(0, y, true);
          const offset = 1*this.density;
          ctx.moveTo(xPixel - offset, yPixel);
          ctx.lineTo(xPixel + offset, yPixel);
          y += 1
        }
      }
      ctx.stroke();

      // line
      ctx.strokeStyle = '#383838';
      ctx.beginPath();
      let x = this.xRange[0];
      ctx.moveTo(...coordToPixels(x, this.eval(x)));

      let wasOffscreen = false;

      for (let i = 0; i < canvas.width; i++) {
        x = i / canvas.width * (this.xRange[1] - this.xRange[0]) + this.xRange[0];
        const y = this.eval(x);
        const offscreen = y < this.yRange[0] || y > this.yRange[1];

        if (wasOffscreen && offscreen) {
          ctx.moveTo(...coordToPixels(x, y));
        } else {
          ctx.lineTo(...coordToPixels(x, y));
        }
        wasOffscreen = offscreen;
      }
      ctx.stroke();
    },
    drawGradient() {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const ctx = canvas.getContext('2d')!;

      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

      for (let i = 0; i < canvas.width; i++) {
        const x = i / canvas.width * (this.xRange[1] - this.xRange[0]) + this.xRange[0];
        const y = this.eval(x);

        const color = y*255;
        gradient.addColorStop(i/canvas.width, `rgb(${color}, ${color}, ${color})`);
      }

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    },
  },
  computed: {
    eval(): Function {
      return new Function('x', `'use strict'; return (${this.expression});`);
    }
  },
  watch: {
    hover: 'draw',
    expression: 'draw',
    xRange: 'draw',
    yRange: 'draw',
  }
})

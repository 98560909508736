import {Mutex, MutexInterface, Semaphore, SemaphoreInterface, withTimeout} from 'async-mutex';

function uniformNoise() {
  return (Math.random()-0.5) * 2;
}

function clampedNoise() {
  return Math.min(Math.max((Math.random()-0.5) * 4, -1), 1);
}

export default async function noiseBackground(options: {
    color: [number, number, number],
    valueNoise: number,
    chromaNoise: number
}): Promise<Blob> {
    const {color, valueNoise, chromaNoise} = options;

    console.log('drawing canvas')
    console.time('draw')
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')
    if (!context) throw new Error('canvas has no context')

    const tileWidth = 128, tileHeight = 128;
    const imageData = context.createImageData(tileWidth, tileHeight);
    let imageI = 0

    for (let y = 0; y < tileHeight; y++) {
      for (let x = 0; x < tileWidth; x++) {
        const value = valueNoise*clampedNoise();
        const r = color[0]+value+clampedNoise()*chromaNoise;
        const b = color[1]+value+clampedNoise()*chromaNoise;
        const g = color[2]+value+clampedNoise()*chromaNoise;
        imageData.data[imageI+0] = r;
        imageData.data[imageI+1] = g;
        imageData.data[imageI+2] = b;
        imageData.data[imageI+3] = 255;
        imageI += 4;
      }
    }

    // get a background tile image
    canvas.width = tileWidth;
    canvas.height = tileHeight;
    context.putImageData(imageData, 0, 0);
    console.timeLog('draw', 'sync ended')

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (blob) {
                console.timeEnd('draw')
                resolve(blob)
            } else {
                reject(new Error('blob was null'))
            }
        })
    })

}

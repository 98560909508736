











import Vue, { PropType } from 'vue'
import TinyshaderEngine from '@/lib/TinyshaderEngine';
import { blobDownload, delay } from '@/util';

type RecordState = 'idle'|'recording'|'recordingComplete'|'downloadingFfmpeg'|'transcoding'

export default Vue.extend({
  name: 'RecordControl',
  props: {
    engine: Object as PropType<TinyshaderEngine>,
  },
  data() {
    return {
      recordingStartTime: null as number|null,
      error: null as Error|null,
      state: 'idle' as RecordState,
      status: '' as string,
    }
  },
  methods: {
    async record() {
      this.error = null;
      let statusUpdateTimer = 0;

      try {
        statusUpdateTimer = window.setInterval(this.updateStatus, 100);
        this.recordingStartTime = Date.now();

        this.state = 'recording'
        this.updateStatus()

        const video = await this.engine.recordVideo({duration: 10*1000})

        this.state = 'recordingComplete'
        this.updateStatus()

        await delay(500);

        let mp4Blob;

        if (video.blob.type == 'video/mp4') {
          mp4Blob = video.blob;
        } else {
          this.state = 'downloadingFfmpeg'
        this.updateStatus()
          const transcoder = (await import(/* webpackChunkName: "ffmpeg" */ '@/lib/transcoder-ffmpegwasm')).default

          this.state = 'transcoding'
        this.updateStatus()
          if (video.blob.type == "video/webm\;codecs=h264") {
            mp4Blob = await transcoder.repackageToMp4(video.blob);
          } else {
            mp4Blob = await transcoder.convertToMp4(video.blob);
          }
        }

        blobDownload(mp4Blob, 'tinyshader');
      } catch (error) {
        console.error('recording failed', error);
        this.error = error;
      } finally {
        this.recordingStartTime = null;
        this.state = 'idle'
        this.updateStatus()
        window.clearInterval(statusUpdateTimer)
      }
    },
    updateStatus() {
      const actions: {[k in RecordState]: ()=>string} = {
        idle: () => '',
        recording: () => {
          const elapsed = Date.now() - (this.recordingStartTime ?? 0)
          return `Recording video: ${(elapsed/1000).toFixed(0)}/10s`
        },
        recordingComplete: () => 'Recording complete.',
        downloadingFfmpeg: () => 'Downloading ffmpeg.wasm…',
        transcoding: () => "Transcoding video…"
      }
      this.status = actions[this.state]()
    }
  }
})

import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            UtilityMixin__mounted_callbacks: [] as (()=>void)[],
            UtilityMixin__isMounted: false,
        }
    },
    mounted() {
        this.UtilityMixin__isMounted = true;
        this.UtilityMixin__mounted_callbacks.forEach(callback => callback())
        this.UtilityMixin__mounted_callbacks = []
    },
    methods: {
        /** a promise that resolves when the component mounts. If already
         * mounted, returns a resolved Promise */
        async waitForMount(): Promise<void> {
            if (this.UtilityMixin__isMounted) {
                return Promise.resolve()
            }
            return new Promise(resolve => {
                this.UtilityMixin__mounted_callbacks.push(resolve)
            })
        }
    },
})

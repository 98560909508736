import firebase from 'firebase/app';
import { FirestoreQueryObserver } from './util';
import _uniqBy from 'lodash/uniqBy';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import { Mutex } from 'async-mutex';
import router from '@/router';

export interface MutableShader {
    code: string,
    name: string,
    creationDate: number,
    authorUsernames: string[],
    parentShaderId: string|null,
}

export type Shader = Readonly<MutableShader>

export interface MutableDatabaseShader extends MutableShader {
    modificationDate: number,
    ownerUID: string,
    authorUIDs: string[],
    authorUsernames: string[],
    isDeleted: boolean,
    doc: firebase.firestore.DocumentSnapshot|null,
}

export type DatabaseShader = Readonly<MutableDatabaseShader>

export type ShaderId = string;

export class User {
    constructor(readonly firebaseUser: firebase.User, readonly username: string) {
    }

    get uid() {
        return this.firebaseUser.uid;
    }

    get db() {
        return firebase.firestore()
    }

    get myShadersQuery() {
        return this.db.collection('shaders')
            .where('ownerUID', '==', this.uid)
            .where('isDeleted', '==', false)
            .orderBy('modificationDate', 'desc')
    }

    _latestShadersObserver: FirestoreQueryObserver | null = null

    async latestShaders(): Promise<DatabaseShader[]> {
        if (!this._latestShadersObserver) {
            const query = this.myShadersQuery.limit(50)
            this._latestShadersObserver = await FirestoreQueryObserver.create(query)
        }

        return this._latestShadersObserver.docs.map(this.shaderFromFirebaseDocument)
    }

    olderShaders: DatabaseShader[] = []

    async loadMoreShaders(after: DatabaseShader): Promise<DatabaseShader[]> {
        const snapshot = await this.myShadersQuery.startAfter(after.doc!).limit(50).get()
        const shaders = snapshot.docs.map(this.shaderFromFirebaseDocument)

        this.olderShaders.push(...shaders)

        return shaders
    }

    get allShaders() {
        const latestShaders = this._latestShadersObserver?.docs.map(this.shaderFromFirebaseDocument)
        console.log('latestShaders', latestShaders)

        const shaders = [
            ...(latestShaders ?? []),
            ...this.olderShaders
        ]

        return _uniqBy(shaders, s => s.doc?.id)
    }

    shaderFromFirebaseDocument(doc: firebase.firestore.QueryDocumentSnapshot): DatabaseShader {
        return {
            ...doc.data(),
            doc,
        } as DatabaseShader
    }
}

export class ReadOnlyDatabaseShader implements DatabaseShader {
    name: string;
    code: string;
    ownerUID: string;
    authorUIDs: string[];
    authorUsernames: string[];
    creationDate: number;
    modificationDate: number;
    isDeleted: boolean;
    parentShaderId: string | null;
    doc: firebase.firestore.DocumentSnapshot|null

    constructor(fromShader: DatabaseShader) {
        this.name = fromShader.name;
        this.code = fromShader.code;
        this.ownerUID = fromShader.ownerUID;
        this.authorUIDs = fromShader.authorUIDs;
        this.authorUsernames = fromShader.authorUsernames;
        this.creationDate = fromShader.creationDate;
        this.modificationDate = fromShader.modificationDate;
        this.isDeleted = fromShader.isDeleted;
        this.parentShaderId = fromShader.parentShaderId;
        this.doc = fromShader.doc;
    }

    static async get(databaseId: string): Promise<ReadOnlyDatabaseShader> {
        const doc = await firebase.firestore().collection('shaders').doc(databaseId).get()
        if (!doc.exists) {
            throw new Error('Shader not found')
        }
        const data = ReadWriteDatabaseShader.cleanDatabaseData(doc.data()!, doc)
        return new ReadOnlyDatabaseShader(data)
    }
}

export class ReadWriteDatabaseShader implements MutableDatabaseShader {
    _code: string
    _name: string
    ownerUID: string
    authorUIDs: string[]
    authorUsernames: string[]
    creationDate: number
    modificationDate: number
    isDeleted: boolean
    parentShaderId: string|null
    doc: firebase.firestore.DocumentSnapshot|null

    constructor(fromShader: DatabaseShader) {
        this._code = fromShader.code
        this._name = fromShader.name
        this.ownerUID = fromShader.ownerUID
        this.authorUIDs = fromShader.authorUIDs
        this.authorUsernames = fromShader.authorUsernames
        this.creationDate = fromShader.creationDate
        this.modificationDate = fromShader.modificationDate
        this.parentShaderId = fromShader.parentShaderId
        this.isDeleted = fromShader.isDeleted
        this.doc = fromShader.doc
    }

    get code() {
        return this._code
    }
    set code(newCode) {
        if (this._code == newCode) return;

        this._code = newCode;
        this.scheduleUpdate();
    }

    get name() {
        return this._name;
    }
    set name(newName) {
        if (this._name == newName) return;

        this._name = newName;
        this.scheduleUpdate();
    }

    get isDatabaseShader() {
        return true
    }

    static async get(databaseId: string): Promise<ReadWriteDatabaseShader> {
        const doc = await firebase.firestore().collection('shaders').doc(databaseId).get()
        if (!doc.exists) {
            throw new Error('Shader not found')
        }
        const data = this.cleanDatabaseData(doc.data()!, doc)
        return new ReadWriteDatabaseShader(data)
    }

    static create(options: { code: string, name: string, creator: User }) {
        const { code, name, creator } = options
        return new ReadWriteDatabaseShader({
            code,
            name,
            creationDate: Date.now(),
            modificationDate: Date.now(),
            ownerUID: creator.uid,
            authorUIDs: [creator.uid],
            authorUsernames: [creator.username || ''],
            parentShaderId: null,
            isDeleted: false,
            doc: null,
        })
    }

    static async createFromExisting(options: { existingShader: Shader, creator: User }) {
        const { existingShader, creator } = options

        // if the existing shader is a database shader, then it's the parent.
        // Otherwise we use the field. This is because URL shaders can store a
        // database ID while they're being edited, then we resolve that back to
        // the real database parent when it's saved to the database.
        const parentShaderId = (existingShader as DatabaseShader)?.doc?.id ?? existingShader.parentShaderId

        let parentShader: DatabaseShader|null = null

        if (parentShaderId) {
            try {
                parentShader = await ReadOnlyDatabaseShader.get(parentShaderId)
            }
            catch (error) {
                console.warn('failed to get the parent shader\'s properties. not a critical error, continuing.', error)
            }
        }

        const authorUIDs = []
        const authorUsernames = []
        if (parentShader) {
            authorUIDs.push(...parentShader.authorUIDs)
            authorUsernames.push(...parentShader.authorUsernames)
        }

        authorUIDs.push(creator.uid)
        authorUsernames.push(creator.username || '')

        return new ReadWriteDatabaseShader({
            code: existingShader.code,
            name: existingShader.name,
            creationDate: existingShader.creationDate,
            modificationDate: Date.now(),
            ownerUID: creator.uid,
            authorUIDs,
            authorUsernames,
            parentShaderId,
            isDeleted: false,
            doc: null,
        })
    }

    needsUpdate = false
    isUpdating = false
    updateError: Error | null = null
    updateTimer: number | null = null
    static UPDATE_DELAY_INTERVAL = 1000

    scheduleUpdate() {
        this.needsUpdate = true

        if (this.updateTimer !== null) {
            window.clearTimeout(this.updateTimer);
            this.updateTimer = null
        }

        this.updateTimer = window.setTimeout(() => {
            this.updateTimer = null
            this.needsUpdate = false
            this.update()
        }, ReadWriteDatabaseShader.UPDATE_DELAY_INTERVAL)
    }

    updateMutex = new Mutex()
    previousUpdateData = {}

    async update() {
        await this.updateMutex.runExclusive(async () => {
            this.isUpdating = true
            this.updateError = null

            try {
                const rawData = _pick(this, [
                    'code',
                    'name',
                    'ownerUID',
                    'authorUIDs',
                    'authorUsernames',
                    'creationDate',
                    'isDeleted',
                ])

                if (_isEqual(rawData, this.previousUpdateData)) return

                this.previousUpdateData = rawData;

                const collection = firebase.firestore().collection('shaders')

                if (!this.doc) {
                    const newShaderFunction = firebase.functions().httpsCallable('newShader')
                    const newShaderResult = await newShaderFunction()
                    const id = newShaderResult.data.id
                    this.doc = await collection.doc(id).get()
                }

                const saveData = {
                    ...rawData,
                    modificationDate: Date.now(),
                }

                await collection.doc(this.doc.id).set(saveData)
            }
            catch (error) {
                console.error('update error', error)
                this.updateError = error
            }
            finally {
                this.isUpdating = false
            }
        })
    }


    get href() {
        const id = this.doc?.id
        if (!id) throw new Error('doesnt get have an id yet')

        return router.resolve({name: 'database-shader', params: {id}}).href
    }

    static cleanDatabaseData(data: Partial<DatabaseShader>, doc: firebase.firestore.DocumentSnapshot): DatabaseShader {
        if (!data.code || !data.creationDate || !data.ownerUID) {
            throw new Error("Invalid shader");
        }

        return {
            code: data.code,
            name: data.name || '',
            creationDate: data.creationDate,
            modificationDate: data.modificationDate || data.creationDate,
            ownerUID: data.ownerUID,
            authorUIDs: data.authorUIDs || [data.ownerUID],
            authorUsernames: data.authorUsernames || [''],
            parentShaderId: data.parentShaderId || null,
            isDeleted: data.isDeleted ?? false,
            doc,
        }
    }
}

export class UrlShader implements MutableShader {
    _code: string
    _name: string
    creationDate: number
    authorUsernames: string[]
    parentShaderId: string|null

    constructor(fromShader: Shader) {
        this._code = fromShader.code
        this._name = fromShader.name
        this.creationDate = fromShader.creationDate
        this.parentShaderId = fromShader.parentShaderId
        this.authorUsernames = fromShader.authorUsernames
    }

    static fromHref(): UrlShader | null {
        const urlParams = new URLSearchParams(window.location.search)

        if (!urlParams.has('c')) {
            return null
        }

        let creationDate = Date.now()

        if (urlParams.has('d')) {
            const urlCreationDate = parseInt(urlParams.get('d')!);
            if (isFinite(urlCreationDate)) {
                creationDate = urlCreationDate;
            }
        }

        const authorUsernames: string[] = []

        if (urlParams.has('a')) {
            authorUsernames.push(...urlParams.get('a')!.split(','))
        }

        return new UrlShader({
            code: urlParams.get('c')!,
            name: urlParams.get('n') || '',
            creationDate,
            parentShaderId: urlParams.get('p') || null,
            authorUsernames,
        })
    }

    static create() {
        return new this({
            code: 'white = x + y;',
            name: '',
            creationDate: Date.now(),
            parentShaderId: null,
            authorUsernames: [],
        })
    }

    static fromDatabaseShader(parent: DatabaseShader) {
        const parentShaderId = parent.doc?.id
        if (!parentShaderId) throw new Error("couldn't get an ID from the parent shader");

        return new this({
            code: parent.code,
            name: parent.name,
            creationDate: parent.creationDate,
            parentShaderId,
            authorUsernames: parent.authorUsernames,
        })
    }

    get code() {
        return this._code
    }
    set code(newCode) {
        if (this._code == newCode) return;
        this._code = newCode;
        this.update();
    }

    get name() {
        return this._name;
    }
    set name(newName) {
        if (this._name == newName) return;
        this._name = newName;
        this.update();
    }

    edited = false

    get href() {
        return router.resolve({
            name: 'url-shader',
            query: {
                // in this dict, 'undefined' means the key isn't set
                c: this.code,
                d: this.creationDate.toFixed(0),
                n: this.name || undefined,
                a: (this.authorUsernames.length > 0
                    ? this.authorUsernames.join(',')
                    : undefined),
                p: this.parentShaderId || undefined
            }
        }).href
    }

    update() {
        const href = this.href;
        if (!this.edited) {
            this.edited = true
            history.pushState({}, "tinyshader - edited", href);
        } else {
            history.replaceState({}, 'tinyshader - edited', href);
        }
    }
}

declare var MediaRecorder: any;

function recordMimeType() {
    const types = [
        "video/mp4",
        "video/webm\;codecs=h264",
        "video/webm",
        "video/webm\;codecs=vp8",
        "video/webm\;codecs=daala",
        "audio/webm\;codecs=opus",
        "video/mpeg",
    ]
    for (const type of types) {
        if (MediaRecorder.isTypeSupported(type)) {
            return type;
        }
    }
    throw new Error('no supported video capture type');
}

export class Video {
    constructor(readonly blob: Blob, readonly thumbnailURL: string) {};
}

export async function recordCanvas(
        canvas: HTMLCanvasElement, options: {duration?: number} = {}
    ): Promise<Video> {
    const {duration=10*1000} = options;

    const thumbnailURL = canvas.toDataURL('image/jpeg', 0.7);

    return new Promise((resolve, reject) => {
        const mimeType = recordMimeType();
        console.log('Capturing video with type '+mimeType);
        const mediaRecorder = new MediaRecorder(canvas.captureStream(), {
            mimeType,
            videoBitsPerSecond: 10e6,
        });
        const chunks: Blob[] = [];
        let abort = false;

        mediaRecorder.ondataavailable = (e: any) => {
            if (e.data.size > 0) {
                chunks.push(e.data);
            }
        }

        mediaRecorder.onstop = () => {
             let blob = new Blob(chunks, {type: mimeType});
             resolve(new Video(blob, thumbnailURL));
        }

        mediaRecorder.onerror =  (err: Error) => {
            abort = true;
            reject(err);
        }

        mediaRecorder.start();

        setTimeout(() => {
            if (abort) return;
            mediaRecorder.stop();
        }, duration);
    })
}

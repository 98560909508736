









import Vue from 'vue'
import TextureBackground from '@/components/TextureBackground.vue';
import noiseBackground from '@/lib/noiseBackground';
import Nav from '@/components/Nav.vue';

export default Vue.extend({
  name: 'App',
  mounted() {
    noiseBackground(
      {color: [245, 245, 245], valueNoise: 3, chromaNoise: 5,}
    ).then(blob => {
      const url = URL.createObjectURL(blob)
      document.body.style.backgroundImage = `url(${url})`;
    }).catch(console.error)
  },
  components: {
    Nav,
  }
})

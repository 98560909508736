
export function blobFileExtension(blob: Blob) {
    const mimeType = blob.type;

    if (mimeType.includes('video/webm')) {
        return 'webm';
    }
    if (mimeType.includes('video/mp4')) {
        return 'mp4';
    }
    if (mimeType.includes('video/mpeg')) {
        return 'mpg';
    }
    throw new Error('unknown capture mimetype');
}

export function blobDownload(blob: Blob, name: string) {
    name = name || 'download';
    let url = window.URL.createObjectURL(blob);
    const extension = blobFileExtension(blob)
    urlDownload(url, name, extension);
    window.URL.revokeObjectURL(url);
}

export function urlDownload(url: string, name: string, extension: string) {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = `${name}.${extension}`;
  a.click();
}

export function delay(millis: number): Promise<void> {
    return new Promise(r => setTimeout(r, millis))
}

export function dateFormat(timestamp: number): string {
    return (new Date(timestamp)).toLocaleString()
}

export function timeAgo(timestamp: number): string {
  const time = timestamp;

  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds == 0) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0,
    format;

  while (format = time_formats[i++]) {
    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice] as string;
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  }
  return '';
}

export function escapeHTML(string: string) {
    var pre = document.createElement('pre');
    var text = document.createTextNode(string);
    pre.appendChild(text);
    return pre.innerHTML;
}








import Vue from 'vue'
import TinyshaderEngine from '@/lib/TinyshaderEngine';

export default Vue.extend({
  name: 'ShaderView',
  props: {
    code: {type: String, default: ''},
    play: {type: Boolean, default: false},
  },
  data() {
    return {
      requestAnimationId: null as number|null,
      engine: null as TinyshaderEngine|null,
    }
  },
  mounted() {
    this.engine = new TinyshaderEngine(this.$refs.canvas as HTMLCanvasElement);
    this.engine.userCode = this.code
    if (this.play) {
      this.engine.start();
    }
  },
  beforeDestroy() {
    this.engine?.stop()
  },
  watch: {
    code(code) {
      if (this.engine) {
        this.engine.userCode = code;
      }
    },
    play(shouldPlay) {
      if (this.engine) {
        if (shouldPlay) {
          this.engine.start();
        } else {
          this.engine.stop();
        }
      }
    }
  },
})

import firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBWCYRv1u9Wf8vzVt-MTWcHAnUyH5imf_U",
    authDomain: "tinyshader.firebaseapp.com",
    databaseURL: "https://tinyshader.firebaseio.com",
    projectId: "tinyshader",
    storageBucket: "tinyshader.appspot.com",
    messagingSenderId: "53723634824",
    appId: "1:53723634824:web:29c976fc36e4399b6f933d",
    measurementId: "G-41C3FC1LKL"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

import Vue from "vue"

export default Vue.extend({
    computed: {
        pageTitle(): string {
            return 'Tinyshader'
        }
    },
    watch: {
        pageTitle: {
            handler(pageTitle: string) {
                document.title = pageTitle
            },
            immediate: true
        }
    }
})

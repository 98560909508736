






































import Vue from 'vue';
import VueContext from 'vue-context';
import auth from '@/lib/auth';

export default Vue.extend({
  name: 'Nav',
  data() {
    return {
      auth,
    }
  },
  methods: {
    login() {
      auth.login().catch(console.error);
    },
    logout() {
      auth.logout().catch(console.error);
    },
    async newShaderPressed(event: MouseEvent) {
      const routeName = this.$route.name
      if (!routeName) return;

      event.preventDefault()
      event.stopPropagation()

      try {
        if (routeName == 'url-shader' || routeName == 'database-shader') {
          // we have to force vue to reload the component, otherwise it clobbers the history.
          await this.$router.push({name: 'blank'})
        }

        await this.$router.replace({name: 'url-shader'})
      }
      catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    username(): string|null {
      return this.auth.user?.username ?? null
    },
    profilePic(): string|null {
      return this.auth.user?.firebaseUser.photoURL ?? null;
    }
  },
  components: {
    VueContext,
  }
})

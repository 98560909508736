import Vue from 'vue'
import Router from 'vue-router'
import Shader from './views/Shader.vue'
import Browse from './views/Browse.vue'
import PageNotFound from './views/PageNotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'url-shader',
      component: Shader,
    },
    {
      path: '/browse',
      name: 'browse',
      component: Browse,
    },
    {
        path: '/:id',
        name: 'database-shader',
        component: Shader,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    { path: '/blank', name: 'blank', component: Vue.extend({
        render(createElement) { return createElement('div') }
    }) }, // noop route
    { path: "*", component: PageNotFound }
  ]
})

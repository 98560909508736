import { Video } from './record';

interface VideoRecord {
    codeHash: string,
    video: Video,
}

const VIDEO_HISTORY_MAX_VIDEOS = 20;

export default class VideoHistory {
    videos: VideoRecord[] = []

    addVideo(codeHash: string, video: Video) {
        this.videos.push({codeHash, video})
        this.removeOldEntries();
    }

    getVideos(codeHash: string): Video[] {
        return this.videos.filter(r => r.codeHash === codeHash).map(r => r.video);
    }

    getVideo(codeHash: string) {
        const videos = this.getVideos(codeHash);
        if (videos.length == 0) {
            return null;
        }

        // return last video recorded
        return videos[videos.length - 1];
    }

    removeOldEntries() {
        while(this.videos.length > VIDEO_HISTORY_MAX_VIDEOS) {
            this.videos.shift();
        }
    }
}














import Vue, { PropType } from 'vue'
import { RenderEngineError } from '@/lib/TinyshaderEngine';

import CodeMirror from "codemirror/src/codemirror.js";
(window as any).CodeMirror = CodeMirror;
import CodeMirrorGLSLMode from '@/vendor/CodeMirror-glsl.js'
CodeMirrorGLSLMode(CodeMirror);
import "codemirror/lib/codemirror.css"
import mixins from 'vue-typed-mixins';
import ObserveSizeMixin from './ObserveSizeMixin';


export default mixins(ObserveSizeMixin).extend({
  props: {
    code: String,
    error: Object as PropType<RenderEngineError>,
  },
  data() {
    return {
      codeMirror: null as any,
      errorMark: null as any,
      errorLine: null as any,
      _latestCode: '',
      intersectionObserver: null as IntersectionObserver|null,
      errorBarPosition: 'bottom' as 'bottom'|'floating'|'top',
    }
  },
  mounted() {
    this.codeMirror = CodeMirror(this.$refs.codeMirrorContainer, {
      value: this.code ?? '',
      mode: 'glsl',
      theme: 'tinyshader',
      tabSize: 2,
      lineWrapping: true,
      lineNumbers: true,
      matchBrackets: true
    })
    this.codeMirror.on('update', () => {
      this._latestCode = this.codeMirror.getValue()
      this.$emit('update:code', this._latestCode)
    });
    this.intersectionObserver = new IntersectionObserver(this.intersectionObserverCallback, {
      root: null,
      threshold: [1, 0.2],
    })
    this.intersectionObserver.observe(this.$el)
  },
  beforeDestroy() {
    this.intersectionObserver?.disconnect()
  },
  methods: {
    intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
      if (entries.length < 1) return

      const entry = entries[0]
      const ratio = entry.intersectionRatio

      if (!entry.rootBounds) return;

      if (entry.rootBounds.bottom > entry.boundingClientRect.bottom) {
        // it's only when the bottom is intersecting that we want to move it
        // up. Otherwise, it should remain on the bottom.
        this.errorBarPosition = 'bottom'
        return;
      }

      if (ratio < 0.2) {
        this.errorBarPosition = 'top'
      } else if (ratio < 1) {
        this.errorBarPosition = 'floating'
      } else {
        this.errorBarPosition = 'bottom'
      }
    },
  },
  computed: {
    textareaStyle(): Partial<CSSStyleDeclaration> {
      return {
        background: this.error ? 'rgb(255, 230, 230)' : 'white',
      }
    },
    errorStyle(): Partial<CSSStyleDeclaration> {
      if (this.errorBarPosition == 'top') {
        return {
          position: 'absolute',
          bottom: '80%',
          left: '0',
          right: '0',
        }
      } else if (this.errorBarPosition == 'bottom') {
        return {
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
        }
      } else {
        return {
          position: 'fixed',
          width: `${this.elementSize.width}px`,
          bottom: '0',
        }
      }
    }
  },
  watch: {
    error(error: RenderEngineError|null): void {
      if (this.errorLine) {
        this.codeMirror.removeLineClass(this.errorLine, 'wrap', 'tinyshader-error-line')
        this.errorLine = null
      }

      if (error && error.lineNumber !== undefined) {
        this.errorLine = this.codeMirror.addLineClass(error.lineNumber-1, 'wrap', 'tinyshader-error-line')
      }
    },
    code(code: string) {
      if (code !== this._latestCode) {
        this.codeMirror.setValue(code)
        this._latestCode = code
      }
    },
  }
})

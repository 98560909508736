<template>
  <div class="tweet-dialog">
    <div class="margins">
      <h2>Tweet your shader</h2>
      <p>Use 'Record' to save a video of your shader.</p>

      <p>Copy this:</p>
      <textarea class="tweet-contents" readonly v-text="tweet"></textarea>

      <p>Open <a href="https://twitter.com" target="_blank">Twitter</a>, paste in the text and attach the video.</p>
    </div>
  </div>
</template>

<script>

const MAX_CODE_LENGTH = 254;
export default {
  name: 'TweetDialog',
  props: {
    shader: Object,
  },
  data() {
    return {
      url: window.location.href
    }
  },
  created() {
    window.addEventListener('locationchange', this.windowLocationChange)
  },
  beforeDestroy() {
    window.removeEventListener('locationchange', this.windowLocationChange)
  },
  methods: {
    windowLocationChange() {
      this.url = window.location.href;
    }
  },
  computed: {
    tweet() {
      const url = new URL(this.shader.href, window.location.href)
      return this.shader.code.trim() + '\n\n' + url;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/lib.scss';

.tweet-dialog {
  background: $share-bg-color;
}
.tweet-dialog h1 {
  font-size: 18px;
  margin-top: 0;
}
.margins {
  padding: 10px;
}
.tweet-contents {
  width: 100%;
  resize: none;
  height: 150px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2) inset;

  font-size: 14px;
  font-family: inherit;
  padding: 8px 8px;
}
</style>

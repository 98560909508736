<template>
  <div class="help">
    <div class="tabs">
      <div class="tab"
           :class="{active: currentTab == 'intro'}"
           @click="currentTab = 'intro'">
        Intro
      </div>
      <div class="tab"
           :class="{active: currentTab == 'io'}"
           @click="currentTab = 'io'">
        Input/Output
      </div>
      <div class="tab"
           :class="{active: currentTab == 'functions'}"
           @click="currentTab = 'functions'">
        Functions
      </div>
      <div class="tab"
           :class="{active: currentTab == 'colormaps'}"
           @click="currentTab = 'colormaps'">
        Colormaps
      </div>
    </div>
    <div class="contents">
      <div class="tab-contents" v-if="currentTab == 'intro'">
        <h2>What's this?</h2>
        <p>
          Shaders are little programs that produce graphics. They turn abstract mathematics into beautiful pictures. Kind of like a mashup between a kaleidoscope, graphing calculator and a supercomputer.
        </p>
        <p>
          The best way to use TinyShader is to just try some garbled maths. Chuck in lots of functions, put functions inside functions, fiddle with numbers.  Periodic functions are ideal, because no matter the input you give them, they'll output something roughly in the right range to be useful as a color.
        </p>
        <p>
          Shaders use a language called GLSL, which is similar to C. But they run on the GPU, which means they're FAST. I mean it. You can abuse that speed to try really stupid stuff - it'll probably work.
        </p>
      </div>
      <div class="tab-contents" v-if="currentTab == 'io'">
        <h2>Inputs</h2>

        <p>The following are variables for use in shaders:</p>
        <ul>
          <li><code>x</code> the x coordinate of the current pixel. -1 → 1</li>
          <li><code>y</code> the y coordinate of the current pixel. -1 → 1</li>
          <li><code>xy</code> a vec2 containing x and y.</li>
          <li><code>r</code> the distance from the middle of the image. 0 → 1.4</li>
          <li><code>theta</code> the angle the current pixel, relative to horizontal. 0 → 6.28</li>
          <li><code>t</code> the current time, in seconds. Starts at zero when the page loads. 0 → ∞</li>
        </ul>

        <h2>Output</h2>

        <p>Set these variables to draw to the screen.</p>
        <ul>
          <li><code>white</code> the white intensity of the current pixel. 0 → 1</li>
          <li><code>color</code> a vec3 of the color at the current pixel.
            <ul>
              <li><code>color.r</code> red 0 → 1</li>
              <li><code>color.g</code> green 0 → 1</li>
              <li><code>color.b</code> blue 0 → 1</li>
            </ul>
          </li>
        </ul>
        <p>These are added together to produce the final color.</p>
      </div>
      <div class="tab-contents" v-if="currentTab == 'functions'">
        <h2>Periodic functions</h2>
        <p>In shaders, periodic functions are the most fun.</p>
        <ul>
          <li><code>sin(x)</code> the sin curve. <MiniGraph expression="Math.sin(x)"/></li>
          <li><code>cos(x)</code> the cos curve. <MiniGraph expression="Math.cos(x)"/></li>
          <li><code>tan(x)</code> the tan curve. <MiniGraph expression="Math.tan(x)"/></li>
          <li><code>square(x)</code> flips between 0 and 1 <MiniGraph expression="((x%2)+2)%2 < 1"/></li>
          <li><code>sawtooth(x)</code> linear up between 0 and 1 <MiniGraph expression="x - Math.floor(x)"/></li>
          <li><code>triangle(x)</code> linear up and down between 0 and 1 <MiniGraph expression="Math.abs((((x%2)+2)%2)-1)"/></li>
        </ul>
        <h2>Smoothing functions</h2>
        <ul>
          <li><code>tanh(x)</code> the tanh curve. <MiniGraph expression="Math.tanh(x)"/></li>
          <li>
            <code>smoothstep(x, start, stop)</code> a step function, with smooth transition.<br>
            <MiniGraph expression="(()=>{var t = Math.min(Math.max(x, 0.0), 1.0); return t*t*(3-2*t);})()"
                       :x-range="[-1, 2]"
                       :y-range="[-0.1, 1.1]" />
          </li>
        </ul>
        <h2>Other functions</h2>
        <ul>
          <li>
            <code>step(x, threshold)</code> step function
            <MiniGraph expression="x>0 ? 1 : 0"
                       :y-range="[-0.1, 1.1]"/>
          </li>
        </ul>

        <p>
          Many more <a href="http://www.shaderific.com/glsl-functions">built-in GLSL functions</a> are available.
        </p>
        <p>
          Note: <code>square</code>, <code>sawtooth</code>, and <code>triangle</code> are not
          OpenGL ES built-in functions, you can find the definition
          <a href="https://github.com/joerick/tinyshader/blob/master/src/shader-template.glsl">here</a>.
        </p>
      </div>
      <div class="tab-contents" v-if="currentTab == 'colormaps'">
        <h2>Colormaps</h2>

        <p>
          Colormaps are a neat way to add some color to your shader. Between 0 and 1, the colormap
          outputs a gradient of color as follows.
        </p>
        <ul>
          <li v-for="colormap in colormaps" :key="colormap.shaderFunctionName">
            <code>{{colormap.shaderFunctionName}}(x)</code>
            <div class="colormap-preview" :style="{background: colormap.css()}"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MiniGraph from './MiniGraph.vue'
import colormaps from '@/colormaps';

export default {
  name: 'Help',
  data() {
    return {
      currentTab: 'functions',
      colormaps,
    }
  },
  components: {
    MiniGraph,
  }
}
</script>

<style lang="scss" scoped>
$header-bg-color: rgba(217,153,0,0.12);
$tab-bg-color: rgba(255, 255, 255, 0.5);

.help {
  letter-spacing: -0.3px;
  font-size: 14px;
}
.tabs {
  background: $header-bg-color;
  padding: 0 7px;
  line-height: 30px;
}
.tabs .title {
  display: inline-block;
  padding-right: 7px;
  opacity: 0.7;
}
.tab {
  display: inline-block;
  padding: 0 7px;
  cursor: pointer;
  // font-weight: 600;
}
.tab.active {
  background: $tab-bg-color;
}
.contents {
  background: rgba(251, 244, 230, 0.56);
  padding: 10px 10px;
}
.minigraph {
  display: block;
}
.colormap-preview {
  height: 30px;
  width: 88%;
  position: relative;
  margin-top: 5px;
  margin-bottom: 25px;
  // border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.1);
  // border-radius: 3px;
}
.colormap-preview::before, .colormap-preview::after {
  position: absolute;
  font-size: 9px;
  top: 108%;
}
.colormap-preview::before {
  content: '0';
  left: -2px;
}
.colormap-preview::after {
  content: '1';
  right: -2px;
}
code {
  font-size: 13px;
  // background: rgba(255, 255, 255, 0.5);
  color: #4d4d4d;
  padding: 0 2px;
}
</style>
